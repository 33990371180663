import React from 'react';

export const CruiseItinerary = () => {
  return (
    <div className="outer-container">
      <div id="itinerary" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Your Cruise Itinerary</h2>
          </div>
        </div>
      </div>
      <div className="itinerary-container">
        <h1>14 Nights | Canada / New England | Oceania Cruises: Allura</h1>
        <div className="itinerary-details">
          <div className="day">
            <div className="day-location">
              <strong>Day 1: </strong> <i>New York</i>
            </div>
            <span>Embarkation aboard the Allura from New York - Pre Dinner Cocktail Party</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 2: </strong> <i>At Sea</i>
            </div>
            <span>Delve right into your state of mind wellness for a 1/2 day session @ Sea</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 3: </strong> <i>Boston, MA</i>
            </div>
            <span>Enjoy a historic day in Boston</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 4: </strong> <i>Portland, ME</i>
            </div>
            <span>Seek out the World’s most photographed lighthouses!</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 5: </strong> <i>St John, ME</i>
            </div>
            <span>Adventure awaits in The Bay of Fundy</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 6: </strong> <i>At Sea</i>
            </div>
            <span>Morning sessions on your wellness journey</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 7: </strong> <i>At Sea</i>
            </div>
            <span>Morning sessions on your wellness journey</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 8: </strong> <i>Quebec City</i>
            </div>
            <span>Enjoy a fun overnight stay in Quebec City and cocktails at the Château Frontenac</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 9: </strong> <i>Quebec City</i>
            </div>
            <span>Fall colors and Montmorency Falls</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 10: </strong> <i>Saguenay</i>
            </div>
            <span>Enjoy the great outdoors, visit Fjord-du-Saguenay</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 11: </strong> <i>At Sea</i>
            </div>
            <span>Schedule some personal time with our experts</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 12: </strong> <i>Sydney</i>
            </div>
            <span>Peggy’s Cove is a must see tour and don’t miss the World’s largest Fiddle!</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 13: </strong> <i>Halifax</i>
            </div>
            <span>The Waterfront is great place to wander and eat!</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 14: </strong> <i>At Sea</i>
            </div>
            <span>Schedule some personal time with our experts</span>
          </div>
          <div className="day">
            <div className="day-location">
              <strong>Day 15: </strong> <i>New York</i>
            </div>
            <span>Disembark in New York</span>
          </div>
        </div>
      </div>
    </div>
  );
};
